import React, { useEffect, useState } from 'react';
import { ReferenciaContenido } from '../interfaces/ReferenciaContenido';
import { getInsideInfo } from '../services/contentful';
import { getEntryDeliveryApi } from '../shared/services/CMS.js';
import SEO from '../shared/seo';
import Loading from '../components/atoms/loading/loading';
import Footer from '../components/molecules/footer/footer';
import PizzaButton from '../components/molecules/pizza-button/pizzaButton';
import HeaderTrees from '../components/organisms/header-trees/headerTrees';
import LibraryComponents from '../components/templates/library-components/libraryComponents';

const TemplateArboles = (props: any) => {
    const pageSlug = props.location.pathname;
    const [pageContext, setPageContext] = useState<any>({});
    const [footerImg, setFooterImg] = useState<any>('');

    useEffect(() => {
        const getPageContext = async () => {
            let dataCont: any = await getEntryDeliveryApi(
                props.pageContext.contentful_id,
            );
            dataCont.referenciaCabecera = await getInsideInfo(
                dataCont.referenciaCabecera,
            );
            dataCont.referenciaContenido = await getInsideInfo(
                dataCont.referenciaContenido,
            );
            dataCont.referenciaCabecera.migaDePan =
                props.pageContext.referenciaCabecera.migaDePan;
            setPageContext(dataCont);
            setFooterImg(
                dataCont.referenciaContenido[2].listaImagenes[1].file.url,
            );
        };
        getPageContext();
    }, []);

    return (
        <div style={{ overflow: 'hidden' }}>
            <SEO
                title={
                    props.pageContext.metaTitulo ||
                    props.pageContext.referenciaCabecera.tituloPrincipal
                }
                description={
                    props.pageContext.metaDescripcion &&
                    props.pageContext.metaDescripcion.json.content[0].content[0]
                        .value
                }
                image={
                    props.pageContext.referenciaCabecera.imagenHeader &&
                    props.pageContext.referenciaCabecera.imagenHeader.file.url
                }
                slug={pageSlug}
                noCanonical={props.pageContext.noCanonical}
                urlCanonical={props.pageContext.urlCanonical}
                noIndex={props.pageContext.noIndex}
                fbId={process.env.FACEBOOK_APP_ID_HOME}
            />
            {pageContext.referenciaContenido && (
                <>
                    <HeaderTrees
                        menuIcon={
                            pageContext.referenciaContenido[0]
                                .referenciaPlantillaContenido[0]
                                .imagenPrincipal[
                                pageContext.referenciaContenido[0]
                                    .referenciaPlantillaContenido[0]
                                    .imagenPrincipal.length - 2
                            ].file.url
                        }
                        closeIcon={
                            pageContext.referenciaContenido[0]
                                .referenciaPlantillaContenido[0]
                                .imagenPrincipal[
                                pageContext.referenciaContenido[0]
                                    .referenciaPlantillaContenido[0]
                                    .imagenPrincipal.length - 3
                            ].file.url
                        }
                        menuBg={
                            pageContext.referenciaContenido[0]
                                .referenciaPlantillaContenido[0]
                                .imagenPrincipal[
                                pageContext.referenciaContenido[0]
                                    .referenciaPlantillaContenido[0]
                                    .imagenPrincipal.length - 4
                            ].file.url
                        }
                        logoTrees={
                            pageContext.referenciaContenido[0]
                                .referenciaPlantillaContenido[0]
                                .imagenPrincipal[
                                pageContext.referenciaContenido[0]
                                    .referenciaPlantillaContenido[0]
                                    .imagenPrincipal.length - 1
                            ].file.url
                        }
                        menuList={
                            pageContext.referenciaContenido[0]
                                .referenciaPlantillaContenido
                        }
                    />
                </>
            )}
            {pageContext.referenciaContenido ? (
                pageContext.referenciaContenido.map(
                    (value: ReferenciaContenido, index: number) => (
                        <div key={index}>
                            <LibraryComponents
                                key={index}
                                content={value}
                                categoryName={pageContext.nombreCategoria}
                            />
                        </div>
                    ),
                )
            ) : (
                <Loading />
            )}
            <PizzaButton />
            <Footer
                isSuperFooter={false}
                footerImg={footerImg}
                footerWhite={true}
            />
        </div>
    );
};

export default TemplateArboles;
