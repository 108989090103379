import React from 'react';
import logoBlanco from '../../../assets/images/logos/comfama/logo-white.svg';
import './headerTrees.scss';

type HeaderTreesProps = {
    logoTrees: string;
    menuIcon: string;
    menuList?: any;
    closeIcon: string;
    menuBg: string;
};

const HeaderTrees = (props: HeaderTreesProps) => {
    const { logoTrees } = props;

    return (
        <div className="o-header-trees">
            <div className="o-header-trees__left">
                <img
                    className="o-header-trees__left__trees-logo"
                    src={logoTrees}
                    loading="lazy"
                />
                <a href={process.env.SITE_URL} rel="referrer follow">
                    <img
                        className="o-header-trees__left__comfama-logo"
                        src={logoBlanco}
                        loading="lazy"
                    />
                </a>
            </div>
        </div>
    );
};

export default HeaderTrees;
